import React from "react";
import HoldersImage from "../../assetes/image/holder.png";

export default function HolderCard () {
  return (
    <div className="bg-white py-6 px-6 rounded-lg shadow-md overflow-hidden">
      {/* <a href="/detail"> */}
        <div className="w-full float-left text-center">
          <figure className="w-full max-w-40 h-40 mx-auto inline-block	">
            <img
              src={HoldersImage}
              alt="Apartment"
              className="w-full h-full rounded-full"
            />
          </figure>
        </div>
        <div className=" w-full float-left">
          <div className="flex items-center text-center gap-2 mb-7 justify-center flex-col	">
            <h3 className="text-lg font-semibold">Nelson Kim</h3>
            <p className="text-slate-600 text-sm">Masters in Applied Computing</p>
            <p className="text-blue-500 text-sm">University of Windsor</p>
          </div>
        </div>
        <div className="w-full float-left">
            <ul className="flex items-center text-center gap-2 justify-center">
              <li>
                <a href="javascript: void(0);">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="4" fill="#EB6601"/>
                        <path d="M20.8782 10.9155L21.5 10.4991C21.5 10.4994 21.5 10.4997 21.5 10.5V19.5C21.5 20.0819 21.0619 20.5 20.6 20.5H9.4C8.93807 20.5 8.5 20.0819 8.5 19.5V10.5C8.5 10.4997 8.5 10.4994 8.5 10.4991L9.12179 10.9155L14.7218 14.6655L15 14.8518L15.2782 14.6655L20.8782 10.9155ZM21.379 10H20.6H9.4H8.62102C8.78283 9.69332 9.0859 9.5 9.4 9.5H20.6C20.9141 9.5 21.2172 9.69332 21.379 10ZM20.6 20H21.1V19.5V12V11.0634L20.3218 11.5845L15 15.1482L9.67821 11.5845L8.9 11.0634V12V19.5V20H9.4H20.6Z" fill="#2677B9" stroke="white"/>
                    </svg>
                </a>
              </li>
              <li>
                <a href="javascript: void(0);">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="30" height="30" rx="4" fill="#EB6601"/>
                        <path d="M10.5294 9H13.5882L15.1176 12.8235L13.2059 13.9706C14.0249 15.6312 15.3688 16.9751 17.0294 17.7941L18.1765 15.8824L22 17.4118V20.4706C22 20.8762 21.8389 21.2652 21.552 21.552C21.2652 21.8389 20.8762 22 20.4706 22C17.4877 21.8187 14.6742 20.552 12.5611 18.4389C10.448 16.3258 9.18127 13.5123 9 10.5294C9 10.1238 9.16113 9.73477 9.44795 9.44795C9.73477 9.16113 10.1238 9 10.5294 9Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
              </li>
            </ul>
          </div>
      {/* </a> */}
    </div>
  );
};