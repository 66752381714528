import React from "react";
import USerImage from "../../assetes/image/user.png"

export default function AccountForm (){
    return(
        <div className="float-left bg-white w-8/12 rounded-lg p-8">
            <div className="flex w-full justify-between	items-center mb-12">
                <div className="flex justify-between items-center gap-4">
                    <figure className="m-0 w-24 h-24">
                        <img src={USerImage} className="w-full h-full rounded-s-full	" />
                    </figure>
                    <div>
                        <h3 className="text-lg font-semibold">Sara Miller</h3>
                        <p className="text-slate-400 text-sm">Tenant</p>
                    </div>
                </div>
                <span className="underline" style={{color: '#EB6601'}}>Edit your profile picture</span>
            </div>
            <div className="w-full float-left">
                <form className="w-full float-left">
                    <fieldset className="w-full float-left m-0 p-0 border-0">
                        <div className="flex justify-between items-center gap-4 mb-5">
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">First Name</label>
                                <input type="text" className="w-full float-left p-3 outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">Last Name</label>
                                <input type="text" className="w-full float-left p-3	outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 mb-5">
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">First Name</label>
                                <input type="text" className="w-full float-left p-3 outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">Last Name</label>
                                <input type="text" className="w-full float-left p-3	outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                        </div>
                        <div className="flex justify-between items-center gap-4 mb-5">
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">First Name</label>
                                <input type="text" className="w-full float-left p-3 outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                            <div className="w-full ">
                                <label className="w-full float-left mb-2">Last Name</label>
                                <input type="text" className="w-full float-left p-3	outline-0 h-10 rounded-md border border-slate-300 border-solid bg-slate-100" />
                            </div>
                        </div>
                        <div>
                            <button type="button" className="px-8 py-2 rounded float-left text-center text-base font-bold" style={{color: '#fff', background: '#EB6601'}}>Save Changes</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}