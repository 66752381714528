import React from "react";

export default function SchedulesCard (){
    return(
        <div className="w-full bg-white rounded-lg float-left px-8 py-3">
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2677B9', background: '#DEEBF5' }}>-$550 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2677B9', background: '#DEEBF5' }}>-$100 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2FD740', background: '#E0F9E2' }}>+35 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2677B9', background: '#DEEBF5' }}>-$550 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2677B9', background: '#DEEBF5' }}>-$100 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">Task of the day</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">10:00 AM</h4>
                        <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2FD740', background: '#E0F9E2' }}>+35 CAD</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}