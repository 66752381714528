// ListingsPage.js
import React from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import HolderCard from "../../holdercard/holdercard";
import CardImage from "../../../assetes/image/cardimage.png";
import ChatImage from "../../../assetes/image/chat.png"


export default function Home () {
  return (
    <div className="flex pl-72 pt-4 pr-4 pb-4 ">
      <Sidebar />
      <div className="flex-1 p">
        <Header />
        <div className="py-6 px-9 w-full float-left rounded-bl-3xl	rounded-br-3xl bg-red-50">
          {/* <h2 className="text-2xl font-bold mb-4">Messages</h2>
          <div>
          </div> */}
          <div className="w-full flex gap-4">
            <div className="flex gap-4 flex-col"style={{width: 'calc(100% - 304px)'}}>
                <div className="w-full bg-white rounded-lg float-left px-4 py-6">
                    <div>
                        <h2 className="text-2xl font-bold mb-4">Hey Sara! welcome back,</h2>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="w-full float-left relative">
                                <figure className="w-full float-left m-0">
                                    <img
                                    src={CardImage}
                                    alt="Apartment"
                                    className="w-full rounded-xl object-cover"
                                    style={{height: '356px'}}
                                    />
                                </figure>
                                <div className="absolute bottom-4 left-4">
                                    <h4 className="text-white text-base leading-4 font-medium">Property</h4>
                                </div>
                            </div>
                            <div>
                                <HolderCard/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <HolderCard/>
                            <HolderCard/>
                            {/* <HolderCard/> */}
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white rounded-lg float-left px-4 py-3">
                    <h2 className="text-2xl font-bold mb-4">To Do’s</h2>
                    <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                        <div className="w-full flex gap-4 items-center">
                            <span>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                                    <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                                </svg>
                            </span>
                            <div className="pr-6 border-solid border-r-2 border-black">
                                <h4 className="text-lg font-semibold">Today</h4>
                                <p className="text-slate-600 text-sm">Task of the day</p>
                            </div>
                            <div className="pl-3">
                                <h4 className="text-lg font-semibold">10:00 AM</h4>
                                <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                        <div className="w-full flex gap-4 items-center">
                            <span>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                                    <path d="M12.4762 9H18.6667V10.2174H21.1429V15.6957H19.9048V11.4348H18.6667V12.6522H12.4762V11.4348H11.2381V21.1739H15.5714V22.3913H10V10.2174H12.4762V9ZM13.7143 11.4348H17.4286V10.2174H13.7143V11.4348ZM20.5238 16.913V19.3478H23V20.5652H20.5238V23H19.2857V20.5652H16.8095V19.3478H19.2857V16.913H20.5238Z" fill="#EB6601"/>
                                </svg>
                            </span>
                            <div className="pr-6 border-solid border-r-2 border-black">
                                <h4 className="text-lg font-semibold">Today</h4>
                                <p className="text-slate-600 text-sm">Task of the day</p>
                            </div>
                            <div className="pl-3">
                                <h4 className="text-lg font-semibold">10:00 AM</h4>
                                <p className="text-slate-600 text-sm">Do a quick tidy-up of the living room.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-72 flex gap-4 flex-col">
                <div className="w-full bg-white rounded-lg float-left px-4 py-3 ">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.2"/>
                            <path d="M16 8C12.6915 8 10 10.6972 10 14.0126C10 19.3438 15.3989 23.5396 15.6287 23.7157L16 24L16.3711 23.7157C16.6009 23.5396 22 19.3438 22 14.0126C22 10.6972 19.3085 8 16 8ZM15.9995 22.4343C14.817 21.4145 11.2225 17.9867 11.2225 14.0126C11.2225 11.3727 13.3654 9.22505 16 9.22505C18.6341 9.22505 20.7775 11.3727 20.7775 14.0126C20.7775 17.9782 17.1815 21.4129 15.9995 22.4343Z" fill="#EB6601"/>
                            <path d="M16.053 17.0001C14.4116 17.0001 13.0762 15.6648 13.0762 14.0234C13.0762 12.382 14.4116 11.0466 16.053 11.0466C17.6945 11.0466 19.0299 12.382 19.0299 14.0234C19.0299 15.6648 17.6945 17.0001 16.053 17.0001ZM16.053 12.0228C14.9499 12.0228 14.0524 12.9203 14.0524 14.0235C14.0524 15.1265 14.9499 16.0239 16.053 16.0239C17.1561 16.0239 18.0536 15.1265 18.0536 14.0235C18.0536 12.9203 17.1562 12.0228 16.053 12.0228Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <h3 className="text-base font-semibold mt-5">1615 College Avenue, N9B 1M2 Windsor, ON</h3>
                </div>
                <div className="w-full bg-white rounded-lg float-left px-4 py-3 ">
                    <h2 className="text-2xl font-bold mb-4">Messages</h2>
                    <ul className="w-full float-left">
                        <li className="flex gap-3.5	items-center w-full">
                            <a href="javascript: void(0);"  className="flex gap-3.5	items-center w-full py-4 border-b border-slate-200">
                                <figure className="w-14	h-14">
                                    <img src={ChatImage} className="rounded-full w-full h-full" />
                                </figure>
                                <div className="flex items-center justify-between" style={{width: 'calc(100% - 70px)'}}>
                                    <div>
                                        <h4 className="text-base font-semibold mb-1">Jason Mandala</h4>
                                        <p className="font-medium text-xs text-slate-500">Not free today.</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li className="flex gap-3.5	items-center w-full">
                            <a href="javascript: void(0);"  className="flex gap-3.5	items-center w-full py-4">
                                <figure className="w-14	h-14">
                                    <img src={ChatImage} className="rounded-full w-full h-full" />
                                </figure>
                                <div className="flex items-center justify-between" style={{width: 'calc(100% - 70px)'}}>
                                    <div>
                                        <h4 className="text-base font-semibold mb-1">Jason Mandala</h4>
                                        <p className="font-medium text-xs text-slate-500">Not free today.</p>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="w-full bg-white rounded-lg float-left px-4 py-3">
                    <h2 className="text-2xl font-bold mb-4">Transactions</h2>
                    <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                        <div className="w-full flex gap-4 items-center">
                            <span>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                                    <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                                </svg>
                            </span>
                            <div className="">
                                <h4 className="text-lg font-semibold">Today</h4>
                                <p className="text-slate-600 text-sm">paid successfully</p>
                            </div>
                        </div>
                        <div>
                            <ul className="flex gap-4 items-center">
                                <li>
                                    <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$550 CAD</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full flex justify-between	items-center py-6">
                        <div className="w-full flex gap-4 items-center">
                            <span className="rotate-180	">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                                    <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                                </svg>
                            </span>
                            <div className="">
                                <h4 className="text-lg font-semibold">Today</h4>
                                <p className="text-slate-600 text-sm">paid successfully</p>
                            </div>
                        </div>
                        <div>
                            <ul className="flex gap-4 items-center">
                                <li>
                                    <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$100 CAD</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};