import React from "react";
import CardImage from "../../assetes/image/cardimage.png";
import Rommate from "../../assetes/image/1.png";
import RommateTwo from "../../assetes/image/2.png";

export default function Card () {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <a href="/detail">
        <div className="w-full float-left relative">
          <figure className="w-full float-left m-0">
            <img
              src={CardImage}
              alt="Apartment"
              className="w-full h-60"
            />
          </figure>
          <span className="absolute top-4	right-4">
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.5" cy="18.5" r="18.5" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3721 19.6215C10.4934 18.7843 10 17.6528 10 16.473C10 15.2867 10.4986 14.1486 11.3868 13.3098C12.275 12.4709 13.48 12 14.7361 12C15.9921 12 17.1972 12.4709 18.0853 13.3098L18.4091 13.6155L18.7329 13.3098C19.621 12.4709 20.8252 12 22.0812 12C23.3382 12 24.5423 12.4709 25.4305 13.3098C26.3187 14.1486 26.8182 15.2867 26.8182 16.473C26.8182 17.6528 26.3248 18.7843 25.4452 19.6215L19.0203 26.0469C18.8618 26.2055 18.6402 26.2955 18.4091 26.2955C18.178 26.2955 17.9563 26.2055 17.7979 26.0469L11.3721 19.6215ZM18.4091 24.3447L24.2246 18.5292L24.242 18.5129C24.8142 17.9716 25.1362 17.2383 25.1362 16.473C25.1362 15.7077 24.8142 14.9744 24.242 14.4331C23.6689 13.8919 22.8915 13.5877 22.0812 13.5877C21.2718 13.5877 20.4945 13.8919 19.9214 14.4331L19.0038 15.3006C18.6749 15.6105 18.1425 15.6105 17.8144 15.3006L16.8959 14.4331C16.3237 13.8919 15.5463 13.5877 14.7361 13.5877C13.9258 13.5877 13.1493 13.8919 12.5762 14.4331C12.0032 14.9744 11.682 15.7077 11.682 16.473C11.682 17.2383 12.0032 17.9716 12.5762 18.5129C12.5823 18.5186 12.5875 18.5235 12.5927 18.5292L18.4091 24.3447Z" fill="#2D2D2D"/>
            </svg>
          </span>
          <div className="absolute bottom-4 left-4">
            <ul className="flex gap-0 pb-2">
              <li>
                <figure>
                  <img src={Rommate} />
                </figure>
              </li>
              <li>
                <figure>
                  <img src={RommateTwo} />
                </figure>
              </li>
            </ul>
            <h4 className="text-white text-base leading-4	font-medium">2 Roommates</h4>
            <span className="text-xs text-slate-300">Richard and Gill</span>
          </div>
        </div>
        <div className="py-4 px-6 w-full float-left">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Apartment</h3>
          </div>
          <p className="text-gray-600 mb-4 text-sm">1995 Broadway, Campbell</p>
          <p className="text-slate-400 text-sm">
            Wifi • Air conditioning • Kitchen • Heating • Balcony
          </p>
          <div className="flex mb-4">
            <span className="text-orange-500 text-sm font-semibold">5.0</span>
            <span className="text-gray-500 text-sm">(14 Reviews)</span>
          </div>
            <span className="text-lg font-semibold">$550 CAD/month</span>
        </div>
      </a>
    </div>
  );
};