import logo from './logo.svg';
import './App.css';
import ListingsPage from "./components/pages/listingpage/listingpage"
import CardDetailPage from './components/pages/detailpage/detailpage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HoldersPage from './components/pages/holders/holders';
import Payments from './components/pages/payments/payments';
import Schedules from './components/pages/schedules/schedules';
import Account from './components/pages/account/account';
import Post from './components/pages/post/post';
import Message from './components/pages/message/message';
import Home from './components/pages/home/home';

function App() {
  return (
  
   
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/listing" element={<ListingsPage />} />
      <Route path="/detail" element={<CardDetailPage />} />
      <Route path="/holders" element={<HoldersPage />} />
      <Route path="/payment" element={<Payments />} />
      <Route path="/schedules" element={<Schedules />} />
      <Route path="/account" element={<Account />} />
      <Route path="/post" element={<Post />} />
      <Route path="/message" element={<Message />} />
    </Routes>
  </Router>

  );
}

export default App;
