// ListingsPage.js
import React from "react";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";
import SchedulesCard from "../../schedulescard/schedulescard";
import AccountForm from "../../accountform/accountform";

export default function Account () {
  return (
    <div className="flex pl-72 pt-4 pr-4 pb-4 ">
      <Sidebar />
      <div className="flex-1 p">
        <Header />
        <div className="py-6 px-9 w-full float-left rounded-bl-3xl	rounded-br-3xl bg-red-50">
          <h2 className="text-2xl font-bold mb-4">My Account</h2>
            {/* <SchedulesCard /> */}
            <AccountForm/>
        </div>
      </div>
    </div>
  );
};