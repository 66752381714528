import React from "react";

export default function PaymentCard (){
    return(
        <div className="w-full bg-white rounded-lg float-left px-8 py-3">
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$550 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#EB6601', border: '2px solid #EB6601'}}>Paid</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span className="rotate-180	">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$100 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#fff', border: '2px solid #EB6601', background: '#EB6601'}}>Pay Now</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2FD740', background: '#E0F9E2' }}>+35 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#EB6601', border: '2px solid #EB6601'}}>Recieved</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$550 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#EB6601', border: '2px solid #EB6601'}}>Paid</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span className="rotate-180	">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#FE5C73', background: '#FFE4E4' }}>-$100 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#fff', border: '2px solid #EB6601', background: '#EB6601'}}>Pay Now</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="w-full flex justify-between	items-center py-6 border-b border-solid border-slate-300">
                <div className="w-full flex gap-4 items-center">
                    <span>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#EB6601" fill-opacity="0.25"/>
                            <path d="M15.175 11.0624L10.1665 15.9192L9 14.788L16 8L16.5833 8.5656L23 14.788L21.8335 15.9192L16.825 11.0624V24H15.175V11.0624Z" fill="#EB6601"/>
                        </svg>
                    </span>
                    <div className="pr-6 border-solid border-r-2 border-black">
                        <h4 className="text-lg font-semibold">Today</h4>
                        <p className="text-slate-600 text-sm">paid successfully</p>
                    </div>
                    <div className="pl-3">
                        <h4 className="text-lg font-semibold">Home Rent</h4>
                        <p className="text-slate-600 text-sm">Rent for month of October is paid.</p>
                    </div>
                </div>
                <div>
                    <ul className="flex gap-4 items-center">
                        <li>
                            <span className="p-1 rounded-3xl w-24 float-left text-center text-xs" style={{ color: '#2FD740', background: '#E0F9E2' }}>+35 CAD</span>
                        </li>
                        <li>
                            <a href="javascripr: void(0);" className="px-2 py-2 rounded-3xl w-24 float-left text-center text-base font-bold" style={{color: '#EB6601', border: '2px solid #EB6601'}}>Recieved</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}